// https://my.onetrust.com/s/article/UUID-d8291f61-aa31-813a-ef16-3f6dec73d643?topicId=0TO1Q000000ssJBWAY#UUID-d8291f61-aa31-813a-ef16-3f6dec73d643_section-idm231900142186795

declare global {
  interface Window {
    OptanonWrapper: Function;
    OnetrustActiveGroups: string;
    _analytics: Function[];
    analytics: IAnalytics;
    OneTrust: any;
    privacy: PrivacyManager;
    tagManager: PrivacyManager;
  }
  interface IAnalytics {
    settings: Function;
    metadata: Function;
    save: Function;
  }
}

class PrivacyManager {
  private queues: Record<string, Function[]> = {};
  private runningQueues: Record<string, boolean> = {};
  private loaded: Record<string, boolean> = {};
  private disabled = false;
  private gtm = "GTM-N2NWNWX";
  private denyAll = false;
  private windowLoaded = false;
  private privacyGroups: Record<string, string> = {};

  init() {
    if (document.location.search.indexOf("_gdpr=deny") > -1) {
      this.denyAll = true;
    }
    if (navigator.userAgent && navigator.userAgent.indexOf("Siteimprove") > -1)
      return;
    let attributes = {
      "data-domain-script": "673cfafc-be0e-4e2a-b756-c1f0673697b3",
    };
    if (document.location.search.indexOf("_gdpr=test") > -1) {
      attributes = {
        "data-domain-script": "673cfafc-be0e-4e2a-b756-c1f0673697b3-test",
      };
    }
    this.load(
      "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
      () => {
        this._initOneTrust();
      },
      attributes
    );
  }

  load(src: string, cb: Function, attr?: Record<string, string>) {
    if (cb) this._pushQueue(src, cb);
    if (!this.loaded[src]) {
      this.loaded[src] = true;
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = src;
      if (attr) {
        for (const key in attr) {
          script.setAttribute(key, attr[key]);
        }
      }
      document.getElementsByTagName("head")[0].appendChild(script);
      script.onload = () => {
        this._runQueue(src);
      };
    }
  }

  private _initOneTrust() {
    window.OptanonWrapper = () => {
      for (const group of window.OneTrust.GetDomainData().Groups) {
        const id = group.CustomGroupId;
        const name = group.GroupName;
        this.privacyGroups[name] = id;
      }
      let groups = [];
      if (this._userAllows("analytics")) {
        this._runQueue("analytics");
        groups.push("analytics");
      }
      if (this._userAllows("functional")) {
        this._runQueue("functional");
        groups.push("functional");
      }
      if (this._userAllows("targeting")) {
        this._runQueue("targeting");
        groups.push("targeting");
      }
      if (this._userAllows("socialmedia")) {
        this._runQueue("socialmedia");
        groups.push("socialmedia");
      }
      console.info("OnetrustActiveGroups", groups);
    };
    if (this.disabled) window.OptanonWrapper();
  }

  private _userAllows(type: string) {
    if (this.denyAll) return false;
    if (this.disabled) return true;
    if (type == "analytics") {
      const group = this.privacyGroups["Performance Cookies"];
      return window.OnetrustActiveGroups.indexOf(group) > -1;
    }
    if (type == "functional") {
      const group = this.privacyGroups["Functional Cookies"];
      return window.OnetrustActiveGroups.indexOf(group) > -1;
    }
    if (type == "targeting") {
      const group = this.privacyGroups["Targeting Cookies"];
      return window.OnetrustActiveGroups.indexOf(group) > -1;
    }
    if (type == "socialmedia") {
      const group = this.privacyGroups["Social Media Cookies"];
      return window.OnetrustActiveGroups.indexOf(group) > -1;
    }
    return false;
  }

  private _runQueue(name: string) {
    this.runningQueues[name] = true;
    if (!this.queues[name] || !this.queues[name].length) return;
    for (let i = 0; i < this.queues[name].length; i++) {
      this.queues[name][i]();
    }
    this.queues[name] = [];
  }

  private _pushQueue(name: string, fn: Function) {
    if (this.runningQueues[name]) {
      fn();
    } else {
      this.queues[name] = this.queues[name] || [];
      this.queues[name].push(fn);
    }
  }

  allows(type: string, fn: Function) {
    this._pushQueue(type, fn);
  }

  getAnalytics(cb: Function) {
    const src = "";
    const ajs = "https://webassets.hbs.edu/libs/analytics/1.0/analytics.min.js";
    this._pushQueue("analytics", () => {
      this.load(ajs, () => {
        cb(window.analytics);
      });
    });
  }

  metadata = function (metadata: any) {
    window._analytics = window._analytics || [];
    window._analytics.push(function () {
      window.analytics.metadata(metadata);
    });
  };

  settings(settings: Record<string, any>) {
    window._analytics = window._analytics || [];
    window._analytics.push(function () {
      window.analytics.settings(settings);
    });
    if (settings.gtm) this.gtm = settings.gtm;
    if (window.addEventListener) {
      window.addEventListener(
        "load",
        () => {
          this.windowLoaded = true;
        },
        false
      );
    }
    this.getAnalytics((analytics: IAnalytics) => {
      if (this.windowLoaded) {
        analytics.save();
      }
    });
    // run the default gtm unless they explicitly say gtm is false
    if (settings.gtm !== false) {
      this.allows("targeting", () => {
        console.info("loading googletagmanager", this.gtm);
        (function (w: any, d: any, s: string, l: string, i: string) {
          w[l] = w[l] || [];
          w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
          const f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", this.gtm);
      });
    }
  }
}

window.privacy = new PrivacyManager();
window.tagManager = window.privacy;
document.addEventListener(
  "DOMContentLoaded",
  () => window.privacy.init(),
  false
);

export {};
